<template>
  <span class="member-status">
    <span class="tag mr-2 status is-info" data-w="badge" v-if="member.badge">{{member.badge}}</span>
    <template v-else>
        <span v-if="member.admin=='1'" class="tag mr-2 status is-info admin">Admin</span>
    </template>
    <span class="tag mr-2 is-danger" v-if="estBloque(member)">Bloqué</span>
    <span class="tag mr-2 is-dark" v-if="estMasque(member)">Masqué</span>
  </span>
</template>

<script>
export default {
    props : ['member']
};
</script>

<style scoped>
.status {
  font-size: 60%;
}
.member-status{
  vertical-align: top;
}
.member-status:empty {
  display: none;
}
</style>